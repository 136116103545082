$(document).ready(() => {
      // Single Ken Burns Image
  $('.inactiveUntilOnLoad').removeClass('inactiveUntilOnLoad');
  
  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click((e) => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  $('.review-slider').slick({
    arrows: false,
    infinite: true,
    dots: true,
    fade: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  //   FAQ
  $('.faq .btn-link').click(e => {
    let target = $(e.currentTarget);
    $('.active-question').removeClass('active-question');
    target.toggleClass('active-question');


    // $('.arrow').toggleClass('rotate');
    $('.arrow').removeClass('rotate');
    target.find( ".arrow" ).addClass('rotate');
    // $('.arrow').addClass('rotate');
    // $(this).toggleClass('rotate');
  });
});
